import { FC } from 'react';
import { SVG, SVGComponentProps } from '../Template';

export const BabyCarSeat: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props}>
      <path
        d="M17.9922 16.502L20.9922 2.00195C20.9922 2.00195 15.7422 3.00195 15.7422 5.00195C15.7422 6.25195 17.7422 7.00195 16.9922 7.75195C16.2422 8.50195 13.4922 9.25195 13.4922 11.252C13.4922 12.502 14.3122 13.432 13.4922 14.252C12.7422 15.002 11.9922 14.609 10.7422 14.252C9.34219 13.852 6.49219 13.502 5.49219 13.752C4.49219 14.002 3.49219 14.502 3.49219 16.002C3.49219 16.827 3.99219 17.752 5.24219 18.002C6.49219 18.252 7.99219 17.002 9.99219 17.002C11.9922 17.002 14.4922 18.502 15.9922 18.502C17.4922 18.502 17.9922 16.502 17.9922 16.502Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M5.49219 18.002L3.99219 22.002H20.4922L17.4922 18.002"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </SVG>
  );
};
