import { FC } from 'react';
import { SVG, SVGComponentProps } from '../Template';

export const OnlineConsultation: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props} size="64" viewBox="0 0 64 64">
      <g clip-path="url(#clip0_6909_28550)">
        <path
          d="M22.0527 27.3973V10.4932H42.8481"
          stroke="#FA2846"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M49.6747 37.9517V53.5292H42.0645"
          stroke="#FA2846"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M22.0528 38.8751C23.7247 38.8751 25.0801 37.5197 25.0801 35.8477C25.0801 34.1757 23.7247 32.8203 22.0528 32.8203C20.3808 32.8203 19.0254 34.1757 19.0254 35.8477C19.0254 37.5197 20.3808 38.8751 22.0528 38.8751Z"
          stroke="#FA2846"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M16.6602 45.357V44.2677C16.6602 41.2897 19.0743 38.8755 22.0524 38.8755C25.0305 38.8755 27.4447 41.2896 27.4447 44.2677V45.357H16.6602Z"
          stroke="#FA2846"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M42.0635 27.3975H2.04102V56.2795H42.0635V27.3975Z"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M2.04102 50.7798H42.0635"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M26.6916 56.2798L31.4131 62.6252H12.6934L17.4149 56.2798"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M39.1885 62.625H4.91602"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M49.8167 1.375C53.5077 1.375 56.4999 4.36713 56.4999 8.05825V12.7858C56.4999 16.5859 53.3947 19.6607 49.5832 19.6112C45.8319 19.5626 42.8477 16.4355 42.8477 12.684V8.05825C42.8477 4.36713 45.8398 1.375 49.5308 1.375H49.8167Z"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37.0918 27.3973C38.717 24.1349 42.0859 21.8936 45.9783 21.8936"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M46.1152 18.6119V21.8937L49.6736 26.0966L53.232 21.8937V18.6118"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M42.0625 37.9522H63.29V31.8157C63.29 26.3358 58.8477 21.8936 53.3679 21.8936"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M42.8477 8.79939H44.5879C46.2133 8.79939 47.772 8.15377 48.9214 7.00439L50.4743 8.55727C51.6237 9.70664 53.1824 10.3523 54.8078 10.3523H56.5"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M53.9238 30.5845H58.0195"
          stroke="#FA2846"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6909_28550">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(0.666016)"
          />
        </clipPath>
      </defs>
    </SVG>
  );
};
export const Hotel: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props} size="64" viewBox="0 0 64 64" stroke="none">
      <path
        d="M43.4561 10.3149C43.0009 9.18453 41.3486 9.22265 40.9076 10.3399C40.6919 10.8864 40.8602 11.5319 41.3155 11.903C41.7655 12.2699 42.4086 12.313 42.9049 12.012C43.472 11.668 43.716 10.9278 43.4561 10.3149Z"
        fill="#1D2939"
      />
      <path
        d="M49.7332 10.3151C49.5236 9.79473 48.9897 9.45223 48.4307 9.46623C47.8724 9.48023 47.3683 9.84173 47.1751 10.365C46.9712 10.917 47.1509 11.5562 47.6131 11.9202C48.0678 12.2785 48.7168 12.3091 49.2043 11.9984C49.7557 11.647 49.9902 10.9201 49.7332 10.3151Z"
        fill="#1D2939"
      />
      <path
        d="M56.0897 10.5711C55.9833 10.0398 55.565 9.61607 55.0372 9.49807C54.4737 9.37219 53.8694 9.62869 53.57 10.1223C53.265 10.6252 53.3135 11.2844 53.6963 11.7329C54.0722 12.1733 54.7042 12.3308 55.2432 12.1196C55.8509 11.8814 56.2257 11.2151 56.0897 10.5711Z"
        fill="#1D2939"
      />
      <path
        d="M26.2711 10.8403C26.2711 10.081 25.6554 9.46533 24.8961 9.46533H9.25781C8.49844 9.46533 7.88281 10.081 7.88281 10.8403C7.88281 11.5997 8.49844 12.2153 9.25781 12.2153H24.8961C25.6554 12.2153 26.2711 11.5997 26.2711 10.8403Z"
        fill="#1D2939"
      />
      <path
        d="M27.7928 27.1485L27.7067 27.7478C27.5679 28.712 27.9714 29.6666 28.7594 30.2392C29.2163 30.5712 29.7554 30.7406 30.2982 30.7406C30.6917 30.7406 31.0873 30.6516 31.4547 30.4708L31.9986 30.2036L32.5424 30.471C33.4168 30.9007 34.4496 30.812 35.2378 30.2393C36.0258 29.6667 36.4293 28.7121 36.2906 27.748L36.2044 27.1486L36.6267 26.7142C37.3057 26.0156 37.5406 25.0061 37.2396 24.0796C36.9384 23.1531 36.1551 22.4743 35.1951 22.3082L34.5979 22.2048L34.3152 21.6692C33.8606 20.8076 32.9729 20.2725 31.9987 20.2725C31.0244 20.2725 30.1368 20.8077 29.6822 21.6692L29.3994 22.205L28.8023 22.3083C27.8422 22.4745 27.0588 23.1532 26.7578 24.0797C26.4568 25.0062 26.6917 26.0157 27.3707 26.7143L27.7928 27.1485ZM30.5342 24.7993C30.9543 24.7267 31.3169 24.4633 31.5159 24.0862L31.9986 23.1717L32.4812 24.0862C32.6802 24.4633 33.0428 24.7267 33.4629 24.7993L34.4814 24.9756L33.7612 25.7165C33.4639 26.0222 33.3254 26.4486 33.3862 26.8706L33.5333 27.8936L32.6052 27.4375C32.4139 27.3435 32.2063 27.2965 31.9986 27.2965C31.7908 27.2965 31.5832 27.3435 31.3919 27.4375L30.4638 27.8936L30.6109 26.8706C30.6717 26.4486 30.5331 26.0222 30.2359 25.7165L29.5157 24.9756L30.5342 24.7993Z"
        fill="#FA2846"
      />
      <path
        d="M50.3805 22.3082L49.7834 22.2048L49.5006 21.6692C49.046 20.8076 48.1585 20.2725 47.1842 20.2725C46.2101 20.2725 45.3224 20.8077 44.8677 21.6692L44.585 22.205L43.9879 22.3082C43.0277 22.4743 42.2444 23.1531 41.9434 24.0797C41.6424 25.0062 41.8772 26.0157 42.5562 26.7142L42.9784 27.1486L42.8922 27.748C42.7535 28.7121 43.157 29.6667 43.945 30.2393C44.4019 30.5713 44.941 30.7407 45.4837 30.7407C45.8772 30.7407 46.2729 30.6517 46.6402 30.471L47.1842 30.2036L47.7281 30.471C48.6024 30.9007 49.6351 30.8121 50.4234 30.2393C51.2115 29.6667 51.6149 28.7121 51.4761 27.748L51.39 27.1486L51.8122 26.7142C52.4912 26.0157 52.7261 25.0061 52.425 24.0797C52.124 23.1531 51.3406 22.4743 50.3805 22.3082ZM48.9469 25.7166C48.6496 26.0223 48.5111 26.4487 48.5719 26.8707L48.719 27.8937L47.7909 27.4376C47.5996 27.3436 47.392 27.2966 47.1842 27.2966C46.9765 27.2966 46.7689 27.3436 46.5776 27.4376L45.6495 27.8937L45.7966 26.8707C45.8574 26.4487 45.7187 26.0223 45.4216 25.7166L44.7014 24.9757L45.7199 24.7995C46.14 24.7268 46.5026 24.4635 46.7016 24.0862L47.1842 23.1717L47.6669 24.0862C47.8659 24.4633 48.2285 24.7267 48.6486 24.7993L49.6671 24.9756L48.9469 25.7166Z"
        fill="#FA2846"
      />
      <path
        d="M16.271 30.471L16.815 30.2036L17.3589 30.471C18.2334 30.9007 19.266 30.812 20.0542 30.2393C20.8422 29.6667 21.2457 28.7121 21.107 27.748L21.0209 27.1486L21.4431 26.7142C22.1221 26.0157 22.357 25.0061 22.056 24.0797C21.755 23.1531 20.9716 22.4743 20.0115 22.3082L19.4144 22.205L19.1316 21.6692C18.6769 20.8076 17.7892 20.2725 16.815 20.2725C15.8407 20.2725 14.9531 20.8077 14.4986 21.6692L14.2159 22.205L13.6187 22.3083C12.6586 22.4745 11.8752 23.1532 11.5742 24.0797C11.2732 25.0062 11.508 26.0157 12.1871 26.7143L12.6092 27.1487L12.5231 27.7481C12.3844 28.7122 12.7879 29.667 13.5759 30.2395C14.0327 30.5715 14.5719 30.7408 15.1146 30.7408C15.508 30.7406 15.9035 30.6516 16.271 30.471ZM14.3321 24.9756L15.3506 24.7993C15.7707 24.7267 16.1334 24.4633 16.3324 24.0862L16.815 23.1717L17.2976 24.0862C17.4966 24.4633 17.8592 24.7268 18.2794 24.7995L19.2979 24.9757L18.5776 25.7166C18.2804 26.0223 18.1419 26.4487 18.2026 26.8707L18.3497 27.8937L17.4216 27.4376C17.0391 27.2495 16.5909 27.2495 16.2085 27.4376L15.2804 27.8937L15.4275 26.8707C15.4882 26.4487 15.3496 26.0223 15.0525 25.7166L14.3321 24.9756Z"
        fill="#FA2846"
      />
      <path
        d="M58.3986 3.38672H5.60138C2.51275 3.38672 0 5.89947 0 8.98809V55.012C0 58.1006 2.51275 60.6135 5.60138 60.6135H58.3986C61.4873 60.6135 64 58.1006 64 55.012V8.98809C64 5.89947 61.4873 3.38672 58.3986 3.38672ZM5.60138 6.13672H58.3986C59.9709 6.13672 61.25 7.41584 61.25 8.98822V15.1707H2.75V8.98822C2.75 7.41584 4.02913 6.13672 5.60138 6.13672ZM58.3986 57.8635H5.60138C4.02913 57.8635 2.75 56.5843 2.75 55.0121V17.9207H61.25V55.0121C61.25 56.5843 59.9709 57.8635 58.3986 57.8635Z"
        fill="#1D2939"
      />
      <path
        d="M48.601 40.2763C47.8416 40.2763 47.226 40.892 47.226 41.6513V42.2254H31.1326V41.0847C31.1326 38.2589 28.8332 35.9598 26.007 35.9598H17.7246V35.1792C17.7246 34.4198 17.109 33.8042 16.3496 33.8042C15.5902 33.8042 14.9746 34.4198 14.9746 35.1792V53.2323C14.9746 53.9917 15.5902 54.6073 16.3496 54.6073C17.109 54.6073 17.7246 53.9917 17.7246 53.2323V51.9826H47.226V53.2323C47.226 53.9917 47.8416 54.6073 48.601 54.6073C49.3604 54.6073 49.976 53.9917 49.976 53.2323V41.6515C49.976 40.892 49.3604 40.2763 48.601 40.2763ZM26.007 38.7098C27.3169 38.7098 28.3826 39.7752 28.3826 41.0847V42.2254H17.7246V38.7098H26.007ZM47.226 49.2326H17.7246V44.9754H47.226V49.2326Z"
        fill="#1D2939"
      />
    </SVG>
  );
};
export const Coupon: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props} size="64" viewBox="0 0 64 64">
      <g clip-path="url(#clip0_6909_28593)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.70703 26.6284H62.957V32.8784C61.2312 32.8784 59.832 34.2775 59.832 36.0034C59.832 37.7293 61.2312 39.1284 62.957 39.1284V45.3783C61.2312 45.3783 59.832 46.7774 59.832 48.5033C59.832 50.2292 61.2312 51.6283 62.957 51.6283V57.8783H1.70703V51.6283C3.43291 51.6283 4.83203 50.2292 4.83203 48.5033C4.83203 46.7774 3.43291 45.3783 1.70703 45.3783V39.1284C3.43291 39.1284 4.83203 37.7293 4.83203 36.0034C4.83203 34.2775 3.43291 32.8784 1.70703 32.8784V26.6284Z"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="22.9256"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.752 26.4955L51.0406 6.12158L54.1656 11.5342C52.671 12.3972 52.1588 14.3085 53.0217 15.8031C53.8847 17.2977 55.796 17.8098 57.2906 16.9468L60.4156 22.3595C59.0173 23.1667 58.479 24.8915 59.1218 26.3342"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="22.9256"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.8945 36.6284L12.6445 47.8784"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="22.9256"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.6445 36.6284H12.6454"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="22.9256"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.8945 47.8784H18.8954"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="22.9256"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.707 27.2534V57.2534"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="22.9256"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.5195 36.6284H52.0195"
          stroke="#FA2846"
          stroke-width="2.5"
          stroke-miterlimit="22.9256"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.5195 42.2534H52.0195"
          stroke="#FA2846"
          stroke-width="2.5"
          stroke-miterlimit="22.9256"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.5195 47.8784H43.2695"
          stroke="#FA2846"
          stroke-width="2.5"
          stroke-miterlimit="22.9256"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6909_28593">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(0.332031)"
          />
        </clipPath>
      </defs>
    </SVG>
  );
};
