'use client';

import * as React from 'react';
import * as TogglePrimitive from '@radix-ui/react-toggle';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@v2/utils';

const toggleVariants = cva(
  [
    'inline-flex items-center justify-center  text-gray-800 rounded-xl',
    'focus-visible:outline-none  focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2  disabled:pointer-events-none disabled:opacity-60 disabled:bg-gray-50 bg-white',
  ],
  {
    variants: {
      variant: {
        primary:
          'text-gray-800 border border-gray-200 data-[state=on]:bg-gray-800 data-[state=on]:border-gray-800  data-[state=on]:text-white',
        outline: 'border-2  data-[state=on]:border-primary-500',
      },
      size: {
        sm: 'min-h-[40px] px-3',
        xl: 'h-[210px] px-16 ',
      },
    },
    defaultVariants: {
      variant: 'outline',
      size: 'xl',
    },
  }
);

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> &
    VariantProps<typeof toggleVariants>
>(({ className, variant, size, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={cn(toggleVariants({ variant, size, className }))}
    {...props}
  />
));

Toggle.displayName = TogglePrimitive.Root.displayName;

export { Toggle, toggleVariants };
