import toast, { Toast } from 'react-hot-toast';
import { Button } from '../Button';
import { Close } from '../Icon';
import { Typography } from '../Typography';

interface Props extends Toast {
  title: string;
  content: string;
}

export function AlertToast(props: Props) {
  const { visible, id, icon, title, content } = props;
  return (
    <div
      className={`${
        visible ? 'animate-enter' : 'animate-leave'
        // eslint-disable-next-line max-len
      } shadopw-lg pointer-events-auto flex w-full max-w-[400px] items-start justify-between rounded-xl bg-white p-[16px] ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex flex-row">
        {icon && <div className="mr-4">{icon}</div>}

        <div className="flex flex-col gap-y-1">
          <Typography size="tsm">{title}</Typography>
          <Typography size="tsm" className="font-normal text-gray-600">
            {content}
          </Typography>
        </div>
      </div>
      <Button variant="ghost" onClick={() => toast.dismiss(id)}>
        <Close viewBox="0 0 20 20" size="20" />
      </Button>
    </div>
  );
}
