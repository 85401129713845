'use client';

import * as React from 'react';
import {
  Button,
  DayPicker,
  useDayPicker,
  useDayRender,
} from 'react-day-picker';

import { cn } from '@v2/utils';
import { ArrowLeft, ArrowRight } from '../Icon';
import { Typography, typographyVariants } from '../Typography';
import { format, isSameDay } from 'date-fns';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '../Tooltip';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...calendarProps
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: cn(
          typographyVariants({ size: 'tmd' }),
          'text-gray-700 font-semibold'
        ),
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          'h-[36px] w-[36px] bg-transparent p-0 opacity-50 hover:opacity-100'
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1 flex items-center justify-end',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: cn(
          typographyVariants({ size: 'tsm' }),
          'text-muted-foreground rounded-md w-[40px]  font-medium text-gray-700'
        ),
        row: 'flex w-full mt-2',
        cell: 'h-[40px] w-[40px] text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-full [&:has([aria-selected].day-range-start)]:rounded-l-full [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-gray-100 first:[&:has([aria-selected])]:rounded-l-full last:[&:has([aria-selected])]:rounded-r-full focus-within:relative focus-within:z-20 z-10  ',
        day: cn(
          typographyVariants({ size: 'tsm' }),
          'h-[40px] w-[40px] p-0 font-normal aria-selected:opacity-100 text-gray-700   z-10'
        ),
        day_range_start: 'day-range-start',
        day_range_end: 'day-range-end',
        day_selected:
          'bg-primary-600 text-white hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground rounded-full',
        day_today: '',
        day_outside:
          'day-outside text-gray-500 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        day_disabled: 'text-gray-500 cursor-disabled',
        day_range_middle:
          'aria-selected:bg-gray-100 aria-selected:text-gray-700',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => (
          <ArrowLeft size="20" className="stroke-gray-800" />
        ),
        IconRight: ({ ...props }) => (
          <ArrowRight size="20" className="stroke-gray-800" />
        ),
        Day: ({ ...props }) => <Day {...props} />,
      }}
      {...calendarProps}
    />
  );
}

export interface DayProps {
  /** The month where the date is displayed. */
  displayMonth: Date;
  /** The date to render. */
  date: Date;
}

/**
 * The content of a day cell – as a button or span element according to its
 * modifiers.
 */
export function Day(props: DayProps): JSX.Element {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(props.date, props.displayMonth, buttonRef);
  const { modifiers } = useDayPicker();

  if (dayRender.isHidden) {
    return <div role="gridcell"></div>;
  }
  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />;
  }

  const getTodayClass = () => {
    if (dayRender.activeModifiers.selected) return 'day_today_selected';

    return 'day_today';
  };

  const isEventDate =
    Boolean(modifiers?.event) &&
    // @ts-ignore
    isSameDay(props.date, modifiers.event);

  const isToday = dayRender.activeModifiers.today;

  const isBtnSelected = dayRender.activeModifiers.selected;
  const isButtonSelectedInMiddle = dayRender.activeModifiers.range_middle;
  const isBtnOutside = dayRender.activeModifiers.outside;

  if (isEventDate && !isBtnOutside)
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="sp;a relative">
            <div
              className={cn(
                'absolute left-1/2 top-[3px] h-0 w-0 -translate-x-1/2 border-l-[6px] border-r-[6px] border-t-[6px] border-l-transparent border-r-transparent border-t-red-500',
                isBtnSelected && !isButtonSelectedInMiddle && 'border-t-white'
              )}
            ></div>
            <Button
              name="day"
              ref={buttonRef}
              {...dayRender.buttonProps}
              className={cn(
                dayRender.buttonProps.className,
                'text-primary-600 aria-selected:text-primary-600',
                isBtnSelected &&
                  !isButtonSelectedInMiddle &&
                  'aria-selected:text-white',
                isToday && getTodayClass()
              )}
            />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <TooltipArrow />
          <Typography size="tsm" className="font-semibold text-gray-700">
            Event date
          </Typography>
          <Typography className="tsm font-semibold text-gray-400">
            {/* @ts-ignore */}
            {format(new Date(modifiers.event!), 'MMM dd, yyyy')}
          </Typography>
        </TooltipContent>
      </Tooltip>
    );

  if (isToday && !isBtnOutside)
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="relative">
            <Button
              name="day"
              ref={buttonRef}
              {...dayRender.buttonProps}
              className={cn(dayRender.buttonProps.className, getTodayClass())}
            />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <TooltipArrow />
          <Typography size="tsm" className="font-semibold text-gray-700">
            Today
          </Typography>
          <Typography className="tsm font-semibold text-gray-400">
            {/* @ts-ignore */}
            {format(new Date(props.date), 'MMM dd, yyyy')}
          </Typography>
        </TooltipContent>
      </Tooltip>
    );
  return (
    <div className="relative">
      <Button
        name="day"
        ref={buttonRef}
        {...dayRender.buttonProps}
        className={cn(dayRender.buttonProps.className)}
      />
    </div>
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
