import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@v2/utils/cn';

const badgeVariants = cva(
  'inline-flex items-center  border   font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 ',
  {
    variants: {
      variant: {
        primary: 'border border-gray-800  bg-gray-800 text-white',
        default: 'border-gray-200 bg-transparent text-gray-500',
        secondary:
          ' mix-blend-multiply bg-gray-50 border-gray-200 text-gray-800',
        success: 'bg-success-100 text-success-700',
        error: 'bg-primary-100 text-primary-600',
        gray: 'bg-gray-100 text-gray-600',
        warning: 'bg-warning-100 text-warning-700',
        blue: 'bg-blue-100 text-blue-700',
      },
      size: {
        default:
          'px-[14px] py-[12px] rounded-[80px] text-[16px] leading-[24px]',
        lg: 'py-1.5 px-2 rounded-2xl  text-sm',
        sm: 'px-3 py-[5px]',
        md: ' px-2.5 py-1 rounded-2xl',
      },
    },
    defaultVariants: {
      size: 'default',
      variant: 'default',
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <div
        className={cn(badgeVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      />
    );
  }
);

Badge.displayName = 'Badge';

export { Badge, badgeVariants };
