'use client';

import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckWhite } from '../Icon';
import { cn } from '@v2/utils/cn';
import { VariantProps, cva } from 'class-variance-authority';

const checkboxCVA = cva(
  'peer shrink-0 rounded-[4px] border border-gray-300  focus-visible:outline-none focus:shadow-primaryShadow disabled:cursor-not-allowed disabled:bg-gray-100 data-[state=checked]:bg-primary-500 data-[state=checked]:text-primary-foreground hover:border-primary-600 hover:bg-primary-100',
  {
    variants: {
      size: {
        sm: 'h-4 w-4',
        md: 'h-5 w-5',
      },
    },

    defaultVariants: {
      size: 'sm',
    },
  }
);

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
    VariantProps<typeof checkboxCVA>
>(({ className, size = 'sm', ...props }, ref) => {
  const iconSize = size === 'sm' ? '12' : '14';

  return (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(checkboxCVA({ size, className }))}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn('flex items-center justify-center text-current')}
      >
        <CheckWhite viewBox={`0 0 ${iconSize} ${iconSize}`} size={iconSize} />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
