import { cn } from '@v2/utils/cn';
import { cva, type VariantProps } from 'class-variance-authority';
import React from 'react';

/* Based on Figma typography Order as Alias
 * D - Display
 * T - Text
 */
export const typographyVariants = cva('font-sans text-gray-800 font-normal', {
  variants: {
    size: {
      dxl: 'text-6xl font-bold   tracking-[-1.2px] ',
      dlg: 'text-[48px]  leading-[60px] tracking-[0.96px] font-bold ',
      dsm: 'leading-[38px] text-[30px] font-bold',
      dmd: 'leading-[30px] text-[36px]  tracking-[-0.72px] ',
      dxs: 'text-[24px] leading-[32px]  ',
      txl: 'leading-[30px] text-[20px] ',
      tlg: 'leading-[20px] text-[18px] ',
      tmd: 'text-[16px] leading-[24px]',
      tsm: 'leading-[20px] text-[14px] font-medium  ',
      txs: 'leading-[18px] text-[12px]   ',
      txxs: 'leading-[16px] text-[10px]   ',
    },
  },
  defaultVariants: {
    size: 'tmd',
  },
});

export interface TypographyProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof typographyVariants> {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';
}

export const Typography = React.forwardRef<HTMLHeadingElement, TypographyProps>(
  ({ className, variant, size, ...props }, ref) => {
    const Comp = variant || 'p';
    return (
      <Comp
        className={cn(typographyVariants({ size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Typography.displayName = 'H1';
