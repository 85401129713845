import { SVG, SVGComponentProps } from '../Template';
import { FC } from 'react';
export const BookingCom: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="143"
      height="24"
      viewBox="0 0 143 24"
      fill="none"
    >
      <g clip-path="url(#clip0_2515_13820)">
        <mask
          id="mask0_2515_13820"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="-1"
          width="142"
          height="25"
        >
          <path
            d="M0.941406 -0.174181H141.83V23.7305H0.941406V-0.174181Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2515_13820)">
          <mask
            id="mask1_2515_13820"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="-1"
            width="142"
            height="25"
          >
            <path
              d="M0.941406 23.7305H141.831V-0.174179H0.941406V23.7305Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_2515_13820)">
            <path
              d="M56.207 2.44403C56.207 1.25744 57.1648 0.297694 58.3438 0.297694C59.5268 0.297694 60.4883 1.25744 60.4883 2.44403C60.4883 3.62908 59.5268 4.58984 58.3438 4.58984C57.1648 4.58984 56.207 3.62908 56.207 2.44403Z"
              fill="#E8EAEE"
            />
            <path
              d="M89.9727 16.7214C89.9727 15.5343 90.9299 14.5735 92.1089 14.5735C93.2915 14.5735 94.2528 15.5343 94.2528 16.7214C94.2528 17.9054 93.2915 18.8672 92.1089 18.8672C90.9299 18.8672 89.9727 17.9054 89.9727 16.7214Z"
              fill="#E8EAEE"
            />
            <path
              d="M21.0715 15.7921C19.2277 15.7921 17.9464 14.3279 17.9464 12.2354C17.9464 10.1435 19.2277 8.68025 21.0715 8.68025C22.9259 8.68025 24.2212 10.1435 24.2212 12.2354C24.2212 14.3609 22.9528 15.7921 21.0715 15.7921ZM21.0715 5.55434C17.1707 5.55434 14.3398 8.36491 14.3398 12.2354C14.3398 16.106 17.1707 18.916 21.0715 18.916C24.9869 18.916 27.8295 16.106 27.8295 12.2354C27.8295 8.36491 24.9869 5.55434 21.0715 5.55434Z"
              fill="#E8EAEE"
            />
            <path
              d="M52.0583 12.5691C51.9067 12.2838 51.7328 12.0422 51.5477 11.8505L51.4297 11.7254L51.5539 11.6063C51.7329 11.4177 51.9159 11.1939 52.0925 10.9345L55.5348 5.81736H51.3559L48.7695 9.82011C48.6231 10.0347 48.3276 10.1431 47.8851 10.1431H47.2956V2.57549C47.2956 1.06186 46.3526 0.855873 45.3339 0.855873H43.5898L43.5928 18.7188H47.2956V13.3605H47.6425C48.0645 13.3605 48.352 13.4094 48.4838 13.6388L50.5264 17.494C51.097 18.5402 51.6656 18.7188 52.7354 18.7188H55.573L53.4596 15.2231L52.0583 12.5691Z"
              fill="#E8EAEE"
            />
            <path
              d="M70.0319 5.52608C68.1484 5.52608 66.9467 6.36274 66.2733 7.07022L66.0484 7.29757L65.9691 6.98835C65.7712 6.23051 65.1044 5.81344 64.1009 5.81344H62.4414L62.4518 18.7148H66.1294V12.7687C66.1294 12.1873 66.205 11.6828 66.3587 11.2225C66.7681 9.82943 67.91 8.96326 69.3372 8.96326C70.4852 8.96326 70.9337 9.56902 70.9337 11.1345V16.7536C70.9337 18.0898 71.5517 18.7148 72.8889 18.7148H74.639L74.6331 10.5094C74.6331 7.24926 73.042 5.52608 70.0319 5.52608Z"
              fill="#E8EAEE"
            />
            <path
              d="M58.3877 5.81738H56.6445L56.6565 15.7927L56.6553 18.7162H58.5159C58.5393 18.7162 58.5581 18.7188 58.5815 18.7188L59.4481 18.7162H60.3108V18.7111H60.3168L60.3242 7.77605C60.3242 6.45671 59.6922 5.81738 58.3877 5.81738Z"
              fill="#E8EAEE"
            />
            <path
              d="M35.5213 15.7921C33.6776 15.7921 32.3938 14.3279 32.3938 12.2354C32.3938 10.1435 33.6776 8.68025 35.5213 8.68025C37.3718 8.68025 38.6707 10.1435 38.6707 12.2354C38.6707 14.3609 37.4028 15.7921 35.5213 15.7921ZM35.5213 5.55434C31.6166 5.55434 28.7852 8.36491 28.7852 12.2354C28.7852 16.106 31.6166 18.916 35.5213 18.916C39.4326 18.916 42.2799 16.106 42.2799 12.2354C42.2799 8.36491 39.4326 5.55434 35.5213 5.55434Z"
              fill="#E8EAEE"
            />
          </g>
        </g>
        <mask
          id="mask2_2515_13820"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="-1"
          width="142"
          height="25"
        >
          <path
            d="M0.941406 -0.174181H141.83V23.7305H0.941406V-0.174181Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_2515_13820)">
          <mask
            id="mask3_2515_13820"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="142"
            height="24"
          >
            <path
              d="M0.941406 0.291643H141.801V23.6953H0.941406V0.291643Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3_2515_13820)">
            <mask
              id="mask4_2515_13820"
              style={{ maskType: 'luminance' }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="-1"
              width="142"
              height="25"
            >
              <path
                d="M0.941406 23.7266H141.831V-0.178085H0.941406V23.7266Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask4_2515_13820)">
              <path
                d="M114.341 15.7902C112.498 15.7902 111.213 14.3259 111.213 12.2335C111.213 10.1416 112.498 8.6783 114.341 8.6783C116.192 8.6783 117.491 10.1416 117.491 12.2335C117.491 14.359 116.223 15.7902 114.341 15.7902ZM114.341 5.55239C110.437 5.55239 107.605 8.36296 107.605 12.2335C107.605 16.104 110.437 18.9141 114.341 18.9141C118.253 18.9141 121.099 16.104 121.099 12.2335C121.099 8.36296 118.253 5.55239 114.341 5.55239Z"
                fill="#E8EAEE"
              />
              <path
                d="M82.3864 15.2201C80.3758 15.2201 79.6601 13.4659 79.6601 11.822C79.6601 11.0978 79.8433 8.73733 82.1932 8.73733C83.361 8.73733 84.9174 9.07199 84.9174 11.9436C84.9174 14.6519 83.54 15.2201 82.3864 15.2201ZM86.8267 5.77977C86.1288 5.77977 85.5918 6.05797 85.3227 6.56608L85.221 6.76341L85.0506 6.61541C84.4569 6.10171 83.3926 5.4924 81.6642 5.4924C78.2253 5.4924 75.9102 8.07512 75.9102 11.9187C75.9102 15.7577 78.3057 18.4406 81.7334 18.4406C82.9042 18.4406 83.8295 18.1675 84.5628 17.6121L84.8467 17.399V17.7575C84.8467 19.4807 83.7322 20.4313 81.7121 20.4313C80.7303 20.4313 79.8367 20.1922 79.2392 19.9756C78.4588 19.7401 78.0016 19.9349 77.6852 20.7176L77.3933 21.4399L76.9803 22.4947L77.2362 22.631C78.5291 23.3171 80.2121 23.7266 81.7334 23.7266C84.8654 23.7266 88.5225 22.1234 88.5225 17.6121L88.5358 5.77977H86.8267Z"
                fill="#E8EAEE"
              />
            </g>
          </g>
        </g>
        <mask
          id="mask5_2515_13820"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="-1"
          width="142"
          height="25"
        >
          <path
            d="M0.941406 -0.174181H141.83V23.7305H0.941406V-0.174181Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_2515_13820)">
          <mask
            id="mask6_2515_13820"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="-1"
            width="142"
            height="25"
          >
            <path
              d="M0.941406 23.7305H141.831V-0.174179H0.941406V23.7305Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask6_2515_13820)">
            <path
              d="M7.35966 15.6414L4.36597 15.6384V12.0593C4.36597 11.2944 4.66252 10.8966 5.31807 10.8051H7.35966C8.81686 10.8051 9.75838 11.7241 9.75942 13.2108C9.75838 14.7366 8.84026 15.6404 7.35966 15.6414ZM4.36597 5.98644V5.04449C4.36597 4.22004 4.71483 3.82791 5.4804 3.77755H7.01224C8.32601 3.77755 9.11289 4.56386 9.11289 5.87912C9.11289 6.8821 8.57425 8.05241 7.06056 8.05241H4.36597V5.98644ZM11.1831 9.5584L10.6418 9.25426L11.1143 8.85042C11.6637 8.37792 12.5848 7.31596 12.5848 5.48089C12.5848 2.67336 10.4069 0.861177 7.03617 0.861177H3.18948L2.75151 0.860163C1.75217 0.896791 0.952588 1.70954 0.941406 2.71507V18.6513H2.70674C2.71121 18.6523 2.71271 18.6513 2.7142 18.6523L7.11215 18.6513C10.8592 18.6513 13.2777 16.6118 13.2777 13.4528C13.2777 11.752 12.4968 10.2979 11.1831 9.5584Z"
              fill="#E8EAEE"
            />
            <path
              d="M137.505 5.56398C135.979 5.56398 134.503 6.27857 133.556 7.47685L133.29 7.81457L133.081 7.43667C132.4 6.19465 131.227 5.56398 129.597 5.56398C127.888 5.56398 126.741 6.51761 126.209 7.08471L125.86 7.4621L125.726 6.9657C125.532 6.25111 124.896 5.85744 123.932 5.85744H122.386L122.371 18.709H125.883V13.036C125.883 12.539 125.945 12.0472 126.069 11.533C126.405 10.1623 127.326 8.68786 128.875 8.83535C129.83 8.9269 130.297 9.6649 130.297 11.0905V18.709H133.833V13.036C133.833 12.4149 133.891 11.9501 134.031 11.4837C134.316 10.175 135.278 8.83332 136.772 8.83332C137.854 8.83332 138.254 9.44568 138.254 11.0905V16.8302C138.254 18.1287 138.833 18.709 140.13 18.709H141.783L141.786 10.5046C141.786 7.22713 140.344 5.56398 137.505 5.56398Z"
              fill="#E8EAEE"
            />
            <path
              d="M105.566 14.2489C105.556 14.2616 104.044 15.8551 102.055 15.8551C100.242 15.8551 98.4103 14.7428 98.4103 12.2598C98.4103 10.116 99.8304 8.61761 101.863 8.61761C102.523 8.61761 103.274 8.85412 103.391 9.25185L103.409 9.31899C103.679 10.2208 104.499 10.2691 104.66 10.2691L106.584 10.2712V8.5892C106.584 6.37065 103.762 5.56602 101.863 5.56602C97.8021 5.56602 94.8555 8.39338 94.8555 12.2868C94.8555 16.1787 97.77 19.002 101.789 19.002C105.275 19.002 107.171 16.7102 107.189 16.6873L107.29 16.5632L105.767 14.0354L105.566 14.2489Z"
              fill="#E8EAEE"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2515_13820">
          <rect
            width="141.455"
            height="24"
            fill="white"
            transform="translate(0.628906)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const Expedia: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="24"
      viewBox="0 0 120 24"
      fill="none"
    >
      <g clip-path="url(#clip0_2515_13865)">
        <path
          d="M29.798 1.82617C29.1049 1.82617 28.5469 2.38388 28.5469 3.07694V18.3464C28.5469 19.0394 29.1049 19.5971 29.798 19.5971H39.3993C39.5806 19.5971 39.7266 19.4512 39.7266 19.2699V17.5183C39.7266 17.337 39.5806 17.191 39.3993 17.191H31.235V11.6069H38.5762C38.7575 11.6069 38.9035 11.4609 38.9035 11.2796V9.62445C38.9035 9.44313 38.7575 9.29721 38.5762 9.29721H31.235V4.17583H39.3993C39.5806 4.17583 39.7266 4.02992 39.7266 3.8486V2.15341C39.7266 1.97208 39.5806 1.82617 39.3993 1.82617H29.798Z"
          fill="#E8EAEE"
        />
        <path
          d="M62.2142 6.27734C59.9297 6.27734 58.5111 7.0641 57.5613 8.37219V7.00555C57.5613 6.82423 57.4153 6.67823 57.234 6.67823H55.257C55.0757 6.67823 54.9297 6.82423 54.9297 7.00555V23.6754C54.9297 23.8567 55.0757 24.0027 55.257 24.0027H57.2682C57.4495 24.0027 57.5955 23.8567 57.5955 23.6754V17.9726C58.4767 19.2413 59.9046 19.9924 62.2142 19.9923C66.0984 19.9923 68.517 16.9181 68.517 13.1308C68.517 9.34353 66.1484 6.27734 62.2142 6.27734ZM61.6501 8.48263C64.4446 8.48263 65.8194 10.5637 65.8194 13.1308C65.8194 15.6979 64.4446 17.779 61.6501 17.779C58.8556 17.779 57.4808 15.6979 57.4808 13.1308C57.4808 10.5637 58.8556 8.48263 61.6501 8.48263Z"
          fill="#E8EAEE"
        />
        <path
          d="M101.226 6.67773H103.214C103.396 6.67773 103.541 6.82371 103.541 7.00503V19.2718C103.541 19.4531 103.396 19.5991 103.214 19.5991H101.226C101.044 19.5991 100.898 19.4531 100.898 19.2718V7.00503C100.898 6.82371 101.044 6.67773 101.226 6.67773Z"
          fill="#E8EAEE"
        />
        <path
          d="M101.194 1.82031H103.24C103.421 1.82031 103.567 1.96629 103.567 2.14761V4.19308C103.567 4.3744 103.421 4.52038 103.24 4.52038H101.194C101.013 4.52038 100.867 4.3744 100.867 4.19308V2.14761C100.867 1.96629 101.013 1.82031 101.194 1.82031Z"
          fill="#E8EAEE"
        />
        <path
          d="M90.7637 19.9919C93.0483 19.9919 94.4669 19.2051 95.4166 17.897V19.2718C95.4166 19.4531 95.5626 19.5991 95.744 19.5991H97.7209C97.9023 19.5991 98.0483 19.4531 98.0483 19.2718V2.15545C98.0483 1.97413 97.9023 1.82812 97.7209 1.82812H95.7098C95.5285 1.82812 95.3825 1.97413 95.3825 2.15545V8.36921C94.4368 7.1328 93.0733 6.27687 90.7637 6.27689C86.8795 6.27692 84.4609 9.35112 84.4609 13.1384C84.4609 16.9257 86.8295 19.9919 90.7637 19.9919ZM91.3279 17.7866C88.5334 17.7866 87.1585 15.7055 87.1585 13.1384C87.1585 10.5713 88.5334 8.49024 91.3279 8.49024C94.1223 8.49024 95.4972 10.5713 95.4972 13.1384C95.4972 15.7055 94.1223 17.7866 91.3279 17.7866Z"
          fill="#E8EAEE"
        />
        <path
          d="M112.123 6.28516C108.189 6.28516 105.82 9.35132 105.82 13.1386C105.82 16.9259 108.189 19.992 112.123 19.992C114.408 19.992 115.826 19.2053 116.776 17.8972V19.2719C116.776 19.4533 116.922 19.5993 117.103 19.5993H119.08C119.262 19.5993 119.408 19.4533 119.408 19.2719V13.1386V7.00525C119.408 6.82393 119.262 6.67793 119.08 6.67793H117.103C116.922 6.67793 116.776 6.82393 116.776 7.00525V8.38C115.826 7.07191 114.408 6.28516 112.123 6.28516ZM112.687 8.49043C115.482 8.49043 116.857 10.5715 116.857 13.1386C116.857 15.7057 115.482 17.7868 112.687 17.7868C109.893 17.7868 108.518 15.7057 108.518 13.1386C108.518 10.5715 109.893 8.49043 112.687 8.49043Z"
          fill="#E8EAEE"
        />
        <path
          d="M41.8463 6.67578C41.5637 6.67578 41.5131 6.89888 41.6414 7.07561L45.8495 12.838L41.2308 19.1972C41.1025 19.3739 41.1531 19.5971 41.4357 19.5971H43.7142C43.9119 19.5971 44.0145 19.5296 44.0802 19.4327L47.2274 14.7884L50.5054 19.4327C50.5729 19.5284 50.6737 19.5971 50.8713 19.5971H53.237C53.5196 19.5971 53.5707 19.3735 53.4419 19.1972L48.7967 12.838L52.9784 7.07561C53.1066 6.89889 53.056 6.67578 52.7735 6.67578H50.5396C50.342 6.67578 50.2395 6.7433 50.1736 6.84011L47.412 10.8987L44.5591 6.84011C44.4919 6.74419 44.3907 6.67578 44.1931 6.67578H41.8463Z"
          fill="#E8EAEE"
        />
        <path
          d="M76.4858 6.27734C72.5296 6.27734 70.1484 9.1219 70.1484 13.1348C70.1484 17.5608 72.963 19.9923 76.4858 19.9923C80.743 19.9923 82.4211 17.4887 82.5149 17.3949C82.6228 17.287 82.5935 17.0861 82.5028 16.9973L81.2723 15.7909C81.1817 15.7021 81.0092 15.6884 80.8899 15.8087C80.8 15.8994 79.302 17.7928 76.8479 17.7928C74.2394 17.7928 72.9553 16.1948 72.7824 13.8406H82.5029C82.8043 13.8406 82.8208 13.5726 82.8215 13.3966C82.8417 8.61706 80.0641 6.27734 76.4858 6.27734ZM76.4944 8.35719C78.8871 8.35719 79.8894 9.79595 80.1009 11.8454H72.8769C73.161 9.83629 74.2161 8.35719 76.4944 8.35719Z"
          fill="#E8EAEE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.57688 0.00195312H17.1345C19.5987 0.00195312 21.5825 1.98569 21.5825 4.44979V17.007C21.5825 19.4711 19.5987 21.4548 17.1345 21.4548H4.57688C2.1127 21.4548 0.128906 19.4711 0.128906 17.007V4.44979C0.128906 1.98569 2.1127 0.00195312 4.57688 0.00195312ZM6.11785 3.84278C5.91794 3.84278 5.79229 3.88872 5.68642 3.99458L3.54266 6.13828C3.50285 6.17809 3.48048 6.23212 3.48047 6.28841C3.48046 6.4056 3.57549 6.50062 3.6927 6.50062H12.986L7.27273 12.2137C7.16014 12.3263 7.09464 12.3918 7.09465 12.6127L7.09464 14.1159C7.09464 14.2069 7.13605 14.2858 7.19546 14.3452C7.25488 14.4046 7.33368 14.4459 7.42471 14.4459H8.92802C9.14897 14.4459 9.21438 14.3805 9.32697 14.2679L9.32697 14.2679L15.0403 8.55481V17.8479C15.0403 17.9651 15.1353 18.0601 15.2525 18.0601C15.3088 18.0601 15.3628 18.0377 15.4026 17.9979L17.5464 15.8542C17.6523 15.7483 17.6982 15.6227 17.6982 15.4228L17.6982 5.2753C17.6982 5.08717 17.6611 4.90089 17.5891 4.72708C17.5171 4.55328 17.4116 4.39536 17.2786 4.26233C17.1455 4.12931 16.9876 4.02379 16.8138 3.95181C16.64 3.87982 16.4537 3.84277 16.2656 3.84277L6.11785 3.84278ZM6.36954 14.8643H4.30745C4.13496 14.8643 3.99609 15.0031 3.99609 15.1756V17.2505C3.99609 17.423 4.13496 17.5619 4.30745 17.5619H6.36954C6.54203 17.5619 6.6809 17.423 6.6809 17.2505V15.1756C6.6809 15.0031 6.54203 14.8643 6.36954 14.8643Z"
          fill="#E8EAEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_2515_13865">
          <rect
            width="119.29"
            height="24"
            fill="white"
            transform="translate(0.0820312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
