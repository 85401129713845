import { FC } from 'react';
import { SVG, SVGComponentProps } from '../Template';

export const AlertTriangle: FC<SVGComponentProps> = ({
  pathProps,
  ...props
}) => {
  return (
    <SVG {...props}>
      <path
        d="M11.9978 9.00023V13.0002M11.9978 17.0002H12.0078M10.6131 3.89195L2.38823 18.0986C1.93203 18.8866 1.70393 19.2806 1.73764 19.6039C1.76705 19.886 1.91482 20.1423 2.14417 20.309C2.40713 20.5002 2.86239 20.5002 3.77292 20.5002H20.2227C21.1332 20.5002 21.5885 20.5002 21.8514 20.309C22.0808 20.1423 22.2286 19.886 22.258 19.6039C22.2917 19.2806 22.0636 18.8866 21.6074 18.0986L13.3825 3.89195C12.9279 3.10679 12.7006 2.71421 12.4041 2.58235C12.1454 2.46734 11.8502 2.46734 11.5915 2.58235C11.295 2.71421 11.0677 3.10679 10.6131 3.89195Z"
        stroke="#FA2846"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...pathProps}
      />
    </SVG>
  );
};
export const CheckCircle: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props}>
      <g clip-path="url(#clip0_7865_308)">
        <path
          d="M6.25008 9.99996L8.75008 12.5L13.7501 7.49996M18.3334 9.99996C18.3334 14.6023 14.6025 18.3333 10.0001 18.3333C5.39771 18.3333 1.66675 14.6023 1.66675 9.99996C1.66675 5.39759 5.39771 1.66663 10.0001 1.66663C14.6025 1.66663 18.3334 5.39759 18.3334 9.99996Z"
          stroke="#079455"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7865_308">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SVG>
  );
};

export const Bell02: FC<SVGComponentProps> = ({ pathProps, ...props }) => {
  return (
    <SVG {...props}>
      <path
        d="M14 21H10M18 8C18 6.4087 17.3679 4.88258 16.2427 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.8826 2.63214 7.75738 3.75736C6.63216 4.88258 6.00002 6.4087 6.00002 8C6.00002 11.0902 5.22049 13.206 4.34968 14.6054C3.61515 15.7859 3.24788 16.3761 3.26134 16.5408C3.27626 16.7231 3.31488 16.7926 3.46179 16.9016C3.59448 17 4.19261 17 5.38887 17H18.6112C19.8074 17 20.4056 17 20.5382 16.9016C20.6852 16.7926 20.7238 16.7231 20.7387 16.5408C20.7522 16.3761 20.3849 15.7859 19.6504 14.6054C18.7795 13.206 18 11.0902 18 8Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...pathProps}
      />
    </SVG>
  );
};
