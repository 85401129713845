import React, { FC, forwardRef, Ref } from 'react';

export interface SVGComponentProps {
  size?: string;
  height?: string;
  width?: string;
  fill?: string;
  strokeWidth?: string;
  stroke?: string;
  className?: string;
  children?: React.ReactNode;
  viewBox?: string;
  pathProps?: React.SVGProps<SVGPathElement>;
}

export const SVG: FC<SVGComponentProps> = forwardRef<
  SVGSVGElement,
  SVGComponentProps
>(
  (
    {
      size,
      height,
      width,
      fill,
      strokeWidth,
      stroke,
      className,
      children,
      viewBox,
      ...props
    },
    ref: Ref<SVGSVGElement>
  ) => {
    return (
      <svg
        className={className}
        width={size && width ? width : size ? size : '24'}
        height={size && height ? height : size ? size : '24'}
        viewBox={viewBox ? viewBox : '0 0 24 24'}
        fill={fill ? fill : 'none'}
        stroke={stroke ? stroke : 'currentColor'}
        strokeWidth={strokeWidth ? strokeWidth : '2'}
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        {children}
      </svg>
    );
  }
);
