import Lottie, { LottieOptions } from 'lottie-react';

import loaderAnim from './loader.json';
import loader2 from './loader-2.json';
import logoLoader from './logo-loader.json';
type LottieOptionsWithoutAnimationData = Omit<LottieOptions, 'animationData'>;

export function Loader(props: LottieOptionsWithoutAnimationData) {
  return <Lottie {...props} animationData={loaderAnim} loop></Lottie>;
}

export function Loader2(props: LottieOptionsWithoutAnimationData) {
  return <Lottie {...props} animationData={loader2} loop></Lottie>;
}

export function LogoLoader(props: LottieOptionsWithoutAnimationData) {
  return <Lottie {...props} animationData={logoLoader} loop></Lottie>;
}
