import { Typography } from '@v2/ui';
import { PropsWithChildren } from 'react';

export const HorizontalDividerWithText = (props: PropsWithChildren) => {
  return (
    <div className="relative flex items-center">
      <div className="flex-grow border-t border-gray-300"></div>
      <Typography
        className="mx-5 flex-shrink uppercase text-gray-400"
        variant="span"
        size={'tsm'}
      >
        {props.children}
      </Typography>
      <div className="flex-grow border-t border-gray-300"></div>
    </div>
  );
};
