import { FC } from 'react';
import { SVG, SVGComponentProps } from '../Template';

export const Image03: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props} stroke="none">
      <path
        d="M3.56138 17.2733L9.0585 11.7761C9.38851 11.4461 9.55351 11.2811 9.74379 11.2193C9.91116 11.1649 10.0914 11.1649 10.2588 11.2193C10.4491 11.2811 10.6141 11.4461 10.9441 11.7761L16.4046 17.2366M11.668 12.5L14.0585 10.1095C14.3885 9.77946 14.5535 9.61445 14.7438 9.55263C14.9112 9.49825 15.0914 9.49825 15.2588 9.55263C15.4491 9.61445 15.6141 9.77946 15.9441 10.1095L18.3346 12.5M8.33464 7.5C8.33464 8.42047 7.58844 9.16667 6.66797 9.16667C5.74749 9.16667 5.0013 8.42047 5.0013 7.5C5.0013 6.57953 5.74749 5.83333 6.66797 5.83333C7.58844 5.83333 8.33464 6.57953 8.33464 7.5ZM5.66797 17.5H14.3346C15.7348 17.5 16.4348 17.5 16.9696 17.2275C17.44 16.9878 17.8225 16.6054 18.0622 16.135C18.3346 15.6002 18.3346 14.9001 18.3346 13.5V6.5C18.3346 5.09987 18.3346 4.3998 18.0622 3.86502C17.8225 3.39462 17.44 3.01217 16.9696 2.77248C16.4348 2.5 15.7348 2.5 14.3346 2.5H5.66797C4.26784 2.5 3.56777 2.5 3.03299 2.77248C2.56259 3.01217 2.18014 3.39462 1.94045 3.86502C1.66797 4.3998 1.66797 5.09987 1.66797 6.5V13.5C1.66797 14.9001 1.66797 15.6002 1.94045 16.135C2.18014 16.6054 2.56259 16.9878 3.03299 17.2275C3.56777 17.5 4.26784 17.5 5.66797 17.5Z"
        stroke="currentColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SVG>
  );
};
