import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@v2/utils/cn';

const containerVariants = cva('mx-auto ', {
  variants: {
    variant: {
      base: 'px-4 sm:px-6 lg:px-8',
      auth: 'sm:pl-16 sm:pr-20 max-w-7xl px-4 md:px-20 ',
    },
  },
  defaultVariants: {
    variant: 'auth',
  },
});

export interface ContainerProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof containerVariants> {
  asChild?: boolean;
}

const Container: React.FC<ContainerProps> = ({
  asChild,
  className,
  children,
  variant,
  ...props
}) => {
  const Comp = asChild ? React.Fragment : 'div';
  const containerClasses = cn(containerVariants({ variant }), className);

  return (
    <Comp className={containerClasses} {...props}>
      {children}
    </Comp>
  );
};

export { Container, containerVariants };
