import { FC } from 'react';
import { SVG, SVGComponentProps } from '../Template';

export const ArrowNarrowLeft: FC<SVGComponentProps> = ({
  pathProps,
  ...props
}) => {
  return (
    <SVG {...props}>
      <path
        d="M19 12H5M5 12L12 19M5 12L12 5"
        stroke="black"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        {...pathProps}
      />
    </SVG>
  );
};
export const ArrowDown: FC<SVGComponentProps> = ({ pathProps, ...props }) => {
  return (
    <SVG {...props}>
      <path
        d="M12 5V19M12 19L19 12M12 19L5 12"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...pathProps}
      />
    </SVG>
  );
};
export const ArrowUp: FC<SVGComponentProps> = ({ pathProps, ...props }) => {
  return (
    <SVG {...props}>
      <path
        d="M12 19V5M12 5L5 12M12 5L19 12"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...pathProps}
      />
    </SVG>
  );
};

export const ArrowLeft: FC<SVGComponentProps> = ({ pathProps, ...props }) => {
  return (
    <SVG {...props}>
      <path
        d="M19 12H5M5 12L12 19M5 12L12 5"
        stroke="black"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        {...pathProps}
      />
    </SVG>
  );
};
export const ArrowRight: FC<SVGComponentProps> = ({ pathProps, ...props }) => {
  return (
    <SVG {...props}>
      <path
        d="M5 12H19M19 12L12 5M19 12L12 19"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...pathProps}
      />
    </SVG>
  );
};

export const ChevronRight: FC<SVGComponentProps> = ({
  pathProps,
  ...props
}) => {
  return (
    <SVG {...props}>
      <path
        d="M9 18L15 12L9 6"
        stroke={props.stroke ?? 'black'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...pathProps}
      />
    </SVG>
  );
};

export const ChevronLeft: FC<SVGComponentProps> = ({ pathProps, ...props }) => {
  return (
    <SVG {...props}>
      <path
        d="M15 18L9 12L15 6"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...pathProps}
      />
    </SVG>
  );
};
``;
export const ChevronDown: FC<SVGComponentProps> = ({ pathProps, ...props }) => {
  return (
    <SVG {...props}>
      <path
        d="M6 9L12 15L18 9"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...pathProps}
      />
    </SVG>
  );
};

export const ChevronUp: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props}>
      <path
        d="M18 15L12 9L6 15"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SVG>
  );
};
export const CornerUpLeft: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props}>
      <path
        d="M9 14L4 9M4 9L9 4M4 9H10.4C13.7603 9 15.4405 9 16.7239 9.65396C17.8529 10.2292 18.7708 11.1471 19.346 12.2761C20 13.5595 20 15.2397 20 18.6V20"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SVG>
  );
};
