import { FC } from 'react';
import { SVG, SVGComponentProps } from '../Template';

export const Analytics: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props} size="64" viewBox="0 0 64 64">
      <g clip-path="url(#clip0_2510_13422)">
        <path
          d="M48.8483 39.0446H15.5938C14.9034 39.0446 14.3438 38.485 14.3438 37.7946V34.4277C14.3438 33.7374 14.9034 33.1777 15.5938 33.1777H48.8483C49.5386 33.1777 50.0983 33.7374 50.0983 34.4277V37.7946C50.0983 38.485 49.5386 39.0446 48.8483 39.0446Z"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.66406 45.2129H62.4201"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M56.5974 11.582H57.6374C60.3877 11.582 62.6171 13.8115 62.6171 16.5617V49.7949C62.6171 52.5452 60.3876 54.7745 57.6374 54.7745H6.34681C3.59669 54.7747 1.36719 52.5452 1.36719 49.7949V16.5617C1.36719 13.8114 3.59669 11.582 6.34681 11.582H7.74756"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M42.9409 54.7734H21.0391V62.4252H42.9409V54.7734Z"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.1484 62.4258H48.1237"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M56.239 45.213V4.06409C56.239 2.68897 55.1242 1.57422 53.7491 1.57422H10.2399C8.86475 1.57422 7.75 2.68897 7.75 4.06409V45.213H56.239Z"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.0725 19.5397C46.639 23.9207 42.9432 27.3428 38.4481 27.3428C33.6615 27.3428 29.7812 23.4625 29.7812 18.6759C29.7812 14.034 33.4305 10.2444 38.0168 10.0195"
          stroke="#FA2846"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.7188 8.52148H22.1592"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.7188 14.3086H20.2435"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.7188 20.0957H22.1592"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.7188 25.8828H22.1592"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M49.4459 17.813C49.0362 12.4152 44.7476 8.10271 39.3609 7.65308C38.9088 7.61533 38.5234 7.97933 38.5234 8.43308V17.8632C38.5234 18.2951 38.8736 18.6451 39.3053 18.6451H48.6656C49.1171 18.6452 49.4801 18.2633 49.4459 17.813Z"
          stroke="#FA2846"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2510_13422">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </SVG>
  );
};
export const Partners: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props} size="64" viewBox="0 0 64 64">
      <g clip-path="url(#clip0_2510_13436)">
        <mask
          id="mask0_2510_13436"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="65"
          height="64"
        >
          <path d="M64.0078 0H0.0078125V64H64.0078V0Z" fill="white" />
          <path
            d="M51.0637 14.2223C51.5512 14.7098 51.5512 15.5011 51.0637 15.9898C50.575 16.4773 49.7837 16.4773 49.2962 15.9898C48.8075 15.5011 48.8075 14.7098 49.2962 14.2223C49.7837 13.7336 50.575 13.7336 51.0637 14.2223Z"
            fill="white"
          />
          <path
            d="M16.3294 16.8113C16.8169 17.3 16.8169 18.0913 16.3294 18.5788C15.8406 19.0675 15.0494 19.0675 14.5619 18.5788C14.0731 18.0913 14.0731 17.3 14.5619 16.8113C15.0494 16.3238 15.8406 16.3238 16.3294 16.8113Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2510_13436)">
          <mask
            id="mask1_2510_13436"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-1"
            y="0"
            width="65"
            height="64"
          >
            <path
              d="M-0.0234375 7.62939e-06H63.9766V64H-0.0234375V7.62939e-06Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_2510_13436)">
            <path
              d="M56.4089 27.9628L47.9062 36.4453"
              stroke="#FA2846"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M44.8172 42.1923C46.0386 43.4123 46.0386 45.3911 44.8172 46.6123C44.2072 47.2223 43.4072 47.5273 42.6084 47.5273C41.8084 47.5273 41.0084 47.2223 40.3984 46.6123"
              stroke="#1D2939"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M34.6562 40.8653L40.4012 46.6116C41.6212 47.8316 41.6212 49.8103 40.4012 51.0303C39.7912 51.6403 38.9912 51.9453 38.1911 51.9453C37.3912 51.9453 36.5911 51.6403 35.9812 51.0303"
              stroke="#1D2939"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M34.2041 49.2627L35.9716 51.0302C37.1928 52.2502 37.1928 54.229 35.9716 55.4502C34.7516 56.6702 32.7728 56.6702 31.5528 55.4502L30.2266 54.124"
              stroke="#1D2939"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.25 9.12578L23.82 15.6958C26.1338 15.8708 27.91 16.447 29.5625 18.0995L30.2325 18.7695"
              stroke="#1D2939"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.5491 36.4453L1.22656 25.1416"
              stroke="#1D2939"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.8209 15.6961L7.80469 31.7148"
              stroke="#1D2939"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.22656 25.1426L17.2441 9.12508"
              stroke="#1D2939"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M35.5312 24.0705L49.2314 37.7717C50.4512 38.9917 50.4512 40.9705 49.2314 42.1905C48.01 43.4105 46.0326 43.4105 44.8112 42.1905L39.0662 36.4455"
              stroke="#1D2939"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.6475 42.1905C14.4262 43.4105 12.4487 43.4105 11.2275 42.1905C10.0075 40.9705 10.0075 38.9917 11.2275 37.7717L15.6475 33.3517C16.8675 32.1317 18.8462 32.1317 20.0662 33.3517C20.6762 33.9617 20.9825 34.7617 20.9825 35.5617C20.9825 36.3617 20.6762 37.1617 20.0662 37.7717"
              stroke="#FA2846"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.0612 46.6123C18.8412 47.8323 16.8625 47.8323 15.6425 46.6123C14.4212 45.3911 14.4212 43.4123 15.6425 42.1923L20.0612 37.7736C21.2825 36.5523 23.26 36.5523 24.4812 37.7736C25.0912 38.3836 25.3962 39.1836 25.3962 39.9836C25.3962 40.7823 25.0912 41.5823 24.4812 42.1923"
              stroke="#FA2846"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M24.4834 51.0303C23.2622 52.2503 21.2847 52.2503 20.0634 51.0303C18.8434 49.8103 18.8434 47.8316 20.0634 46.6116L24.4834 42.1916C25.7034 40.9716 27.6822 40.9716 28.9022 42.1916C29.5134 42.8016 29.8184 43.6016 29.8184 44.4016C29.8184 45.2003 29.5134 46.0003 28.9022 46.6116"
              stroke="#FA2846"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M30.2234 54.124L28.8972 55.4502C27.6772 56.6702 25.6984 56.6702 24.4784 55.4502C23.2572 54.229 23.2572 52.2502 24.4784 51.0302L28.8972 46.6115C30.1184 45.3902 32.0972 45.3902 33.3172 46.6115C33.9272 47.2215 34.2322 48.0215 34.2322 48.8202C34.2322 48.9677 34.2222 49.1165 34.2009 49.2627C34.1084 49.909 33.8147 50.5327 33.3172 51.0302L30.2234 54.124Z"
              stroke="#FA2846"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M37.3 22.3045L31.1125 28.492C29.6487 29.9558 27.2737 29.9558 25.8087 28.492C24.345 27.027 24.345 24.6533 25.8087 23.1883L30.8987 18.0995C32.7337 16.2645 34.8861 15.5895 37.4811 15.5895C39.4137 15.5895 40.9012 14.822 42.2674 13.4558"
              stroke="#FA2846"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M56.4148 27.9648L42.2734 13.4561L48.5947 7.5011L62.7348 21.6436L56.4148 27.9648Z"
              stroke="#FA2846"
              stroke-width="2.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2510_13436">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </SVG>
  );
};
export const Savings: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props} size="64" viewBox="0 0 64 64">
      <path
        d="M1.375 47.2617V57.2123"
        stroke="#1D2939"
        stroke-width="2.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.375 47.2617V57.2123"
        stroke="#1D2939"
        stroke-width="2.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.9346 34.9342H30.9844C30.294 34.9342 29.7344 34.3745 29.7344 33.6842V28.3633C29.7344 27.6729 30.294 27.1133 30.9844 27.1133H49.9346C50.625 27.1133 51.1846 27.6729 51.1846 28.3633V33.6842C51.1846 34.3745 50.625 34.9342 49.9346 34.9342Z"
        stroke="#FA2846"
        stroke-width="2.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.8488 27.1138H27.8984C27.2081 27.1138 26.6484 26.5542 26.6484 25.8638V20.543C26.6484 19.8526 27.2081 19.293 27.8984 19.293H46.8488C47.5392 19.293 48.0988 19.8526 48.0988 20.543V25.8638C48.0988 26.5542 47.5391 27.1138 46.8488 27.1138Z"
        stroke="#FA2846"
        stroke-width="2.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.9346 19.2935H30.9844C30.294 19.2935 29.7344 18.7339 29.7344 18.0435V12.7227C29.7344 12.0323 30.294 11.4727 30.9844 11.4727H49.9346C50.625 11.4727 51.1846 12.0323 51.1846 12.7227V18.0435C51.1846 18.7339 50.625 19.2935 49.9346 19.2935Z"
        stroke="#FA2846"
        stroke-width="2.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.8488 11.4713H27.8984C27.2081 11.4713 26.6484 10.9116 26.6484 10.2213V4.90039C26.6484 4.21002 27.2081 3.65039 27.8984 3.65039H46.8488C47.5392 3.65039 48.0988 4.21002 48.0988 4.90039V10.2213C48.0988 10.9116 47.5391 11.4713 46.8488 11.4713Z"
        stroke="#FA2846"
        stroke-width="2.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.7734 27.9355H15.5014"
        stroke="#1D2939"
        stroke-width="2.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.6328 29.8003V26.0723"
        stroke="#1D2939"
        stroke-width="2.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55.8516 7.19531H59.5796"
        stroke="#1D2939"
        stroke-width="2.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.7109 9.06003V5.33203"
        stroke="#1D2939"
        stroke-width="2.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.0952 43.8164C46.3492 43.8164 48.1764 45.6437 48.1764 47.8975C48.1764 50.1515 46.3491 51.9787 44.0952 51.9787H29.6406"
        stroke="#1D2939"
        stroke-width="2.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.4296 40.7441C59.8359 39.1504 57.2518 39.1504 55.658 40.7441L48.1675 48.2345C48.1765 48.1236 48.1824 48.0119 48.1824 47.8986C48.1824 45.6446 46.3551 43.8175 44.1013 43.8175H39.9235C38.0726 43.8175 36.2473 43.3855 34.5926 42.556C32.938 41.7264 31.1126 41.2945 29.2618 41.2945H27.393C25.5798 41.2945 23.7871 41.6791 22.1335 42.4231L20.8604 42.9959C19.2068 43.7399 17.4141 44.1245 15.6009 44.1245H4.51275C2.77988 44.1244 1.375 45.5291 1.375 47.2621V57.2128C1.375 58.9456 2.77988 60.3505 4.51275 60.3505H45.503C47.2146 60.3505 48.8519 59.6514 50.0356 58.4151L61.4298 46.5158C63.0235 44.9219 63.0235 42.3379 61.4296 40.7441Z"
        stroke="#1D2939"
        stroke-width="2.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SVG>
  );
};
export const Sports01: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props} size="64" viewBox="0 0 64 64">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
      >
        <path
          d="M42.2736 14.848C43.704 14.848 44.8636 13.6884 44.8636 12.258C44.8636 10.8276 43.704 9.66797 42.2736 9.66797C40.8432 9.66797 39.6836 10.8276 39.6836 12.258C39.6836 13.6884 40.8432 14.848 42.2736 14.848Z"
          stroke="#FA2846"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M49.7344 9.66211H56.9362"
          stroke="#FA2846"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M49.8086 14.8555H51.7338"
          stroke="#FA2846"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.6253 4.42773H34.4102V19.9117H46.0203L48.5177 22.4091L51.0152 19.9117H62.6253V4.42773Z"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M36.8216 36.6474C37.0189 35.2758 37.1209 33.8742 37.1209 32.4489C37.1209 20.3592 29.7806 9.95717 19.248 5.32617C8.71525 9.95705 1.375 20.3592 1.375 32.4489C1.375 44.5388 8.71525 54.9408 19.2479 59.5717C22.7288 58.0412 25.861 55.8804 28.4947 53.2367"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.2461 27.2207V37.6765"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.1172 26.9707H21.3688"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.1172 32.4492H21.3688"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.1172 37.9258H21.3688"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M39.832 59.3072C40.0205 56.6848 41.1173 54.1155 43.1223 52.1105C45.1423 50.0907 47.735 48.9927 50.3778 48.8164"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M38.6503 37.4062C38.4147 39.9465 37.3245 42.4224 35.3795 44.3673C33.3946 46.352 30.8567 47.4467 28.2617 47.6514"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M39.2479 59.5705C45.5075 59.5705 50.5818 54.4965 50.5818 48.2374C50.5818 41.9783 45.5075 36.9043 39.2479 36.9043C32.9884 36.9043 27.9141 41.9783 27.9141 48.2374C27.9141 54.4965 32.9884 59.5705 39.2479 59.5705Z"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.5425 19.3672H4.82812"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.00391 12.9941H29.4933"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.9163 51.9023H9.25391"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.82812 45.5273H27.7394"
          stroke="#1D2939"
          stroke-width="2.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SVG>
  );
};

export function TravelAgency() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
    >
      <g clip-path="url(#clip0_3108_24554)">
        <mask
          id="mask0_3108_24554"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="-1"
          width="65"
          height="66"
        >
          <path
            d="M0.740234 -0.0175781H64.7715V64.0137H0.740234V-0.0175781Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_3108_24554)">
          <path
            d="M34.4178 17.1074V14.2353C34.4178 13.994 34.6132 13.7986 34.8545 13.7986H43.0635C43.1013 13.7986 43.1318 13.768 43.1318 13.7303V12.9129C43.1318 12.8837 43.1131 12.8577 43.0856 12.8482L34.7138 9.99182C34.5367 9.93154 34.4178 9.76534 34.4178 9.57837V7.63142C34.4178 6.52251 34.1115 5.43523 33.5327 4.48939L33.381 4.33781C32.9413 3.89822 32.3041 3.96488 31.9802 4.49552C31.4038 5.43998 31.0988 6.52488 31.0988 7.63142L31.0975 9.575C31.0975 9.76171 30.9787 9.92767 30.802 9.98807L22.4309 12.8482C22.4034 12.8577 22.3848 12.8837 22.3848 12.9129V13.7303C22.3848 13.768 22.4153 13.7986 22.453 13.7986H30.6621C30.9032 13.7986 31.0988 13.994 31.0988 14.2353V17.1074"
            stroke="#FA2846"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M27.7786 29.4088C27.7786 31.2422 26.2923 32.7285 24.4588 32.7285H21.1421C19.3085 32.7285 17.8223 31.2422 17.8223 29.4088V24.4724H27.7786V29.4088Z"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M37.7375 29.4088C37.7375 31.2422 36.2511 32.7285 34.4177 32.7285H31.101C29.2675 32.7285 27.7812 31.2422 27.7812 29.4088V24.4724H37.7375V29.4088Z"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M47.6927 29.4088C47.6927 31.2422 46.2063 32.7285 44.3728 32.7285H41.0562C39.2226 32.7285 37.7363 31.2422 37.7363 29.4088V24.4724H47.6927V29.4088Z"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M40.7134 0.921397H18.0713C17.9338 0.921397 17.8223 1.03295 17.8223 1.17039V17.1074H47.6913V1.17039C47.6913 1.03295 47.5798 0.921397 47.4424 0.921397H45.0906"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M47.6934 17.1074H51.5705V9.06687C51.5705 8.92931 51.4589 8.81788 51.3215 8.81788H47.6934"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.8244 8.81788H14.1963C14.0588 8.81788 13.9473 8.92943 13.9473 9.06687V17.1074H17.8244"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M58.4426 58.6H7.07453C6.90394 58.6 6.76562 58.7382 6.76562 58.9089V62.7673C6.76562 62.938 6.90394 63.0762 7.07453 63.0762H58.4426C58.6132 63.0762 58.7515 62.938 58.7515 62.7673V58.9089C58.7515 58.7382 58.6132 58.6 58.4426 58.6Z"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M52.4476 58.5996V36.1589C52.4476 36.0696 52.3752 35.9972 52.2859 35.9972H37.898C37.8087 35.9972 37.7363 36.0696 37.7363 36.1589V58.5996"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M42.661 52.0723C41.7468 52.0723 41.0059 51.3312 41.0059 50.4172V43.5908C41.0059 42.6766 41.7468 41.9354 42.661 41.9354C43.5751 41.9354 44.3161 42.6766 44.3161 43.5908V50.4172C44.3161 51.3312 43.5751 52.0723 42.661 52.0723Z"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.9904 35.9983L13.0684 43.9204V47.7913C13.0684 47.8807 13.1408 47.9531 13.2301 47.9531H14.2895L26.2442 35.9983H20.9904Z"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M26.2477 35.9983L14.293 47.9531H18.3993L30.3541 35.9983H26.2477Z"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.2301 35.9998C13.1408 35.9998 13.0684 36.0722 13.0684 36.1615V43.9219L20.9904 35.9998H13.2301Z"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M22.2812 47.9531H32.4707C32.56 47.9531 32.6326 47.8807 32.6326 47.7913V37.6018L22.2812 47.9531Z"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M32.6343 36.16C32.6343 36.0707 32.5619 35.9983 32.4725 35.9983H30.3512L18.3965 47.9531H22.283L32.6343 37.6018V36.16Z"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.86914 24.9811V29.4088C7.86914 31.2422 9.35549 32.7285 11.1889 32.7285H14.5056C16.3391 32.7285 17.8254 31.2422 17.8254 29.4088V24.4725H7.96369C7.90166 24.6336 7.86914 24.8059 7.86914 24.9811Z"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M47.6934 24.4724V29.4088C47.6934 31.2422 49.1797 32.7285 51.0131 32.7285H54.3298C56.1634 32.7285 57.6496 31.2422 57.6496 29.4088V24.981C57.6496 24.8058 57.6172 24.6334 57.555 24.4724H47.6934Z"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25.1816 11.9082V10.6266"
            stroke="#FA2846"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M27.9844 10.9492V9.50064"
            stroke="#FA2846"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M37.5312 10.9492V9.50064"
            stroke="#FA2846"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M40.3379 11.9082V10.6266"
            stroke="#FA2846"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M27.7812 24.4707L28.9971 17.1057"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.4698 17.1057L17.8223 24.4707"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M37.7334 24.4707L36.5176 17.1057"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M28.9971 17.1057L27.7812 24.4707"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M47.6905 24.4707L44.043 17.1057"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M36.5176 17.1057L37.7334 24.4707"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.8223 24.4707L21.4698 17.1059"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.9488 17.1076L8.19336 24.0801"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M52.539 18.2832L51.5684 17.1074"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M57.5505 24.4707C57.496 24.3294 57.4189 24.1968 57.3213 24.0783L55.3926 21.7417"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M44.043 17.1059L47.6905 24.4707"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M55.7188 58.5996V32.4242"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.79688 56.3239V58.5996"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.79688 32.4247V52.0723"
            stroke="#1D2939"
            stroke-width="1.6"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3108_24554">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function TravelAgent() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
    >
      <g clip-path="url(#clip0_3108_24633)">
        <path
          d="M7.75391 20.5021V15.8496"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.13477 11.4855C8.25602 11.0792 8.40727 10.6805 8.58727 10.2942C9.51727 8.29297 11.1298 6.74922 13.0723 5.88672"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37.6016 20.5011V14.0643C37.6016 12.7618 37.3172 11.4752 36.7682 10.2941C35.8642 8.34894 34.3154 6.83581 32.4453 5.96094"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.4824 46.2422L36.2004 47.1418"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.9668 38.3906V42.5886"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.3848 42.5883V38.4453"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.9368 46.2305L11.5605 46.683"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.2793 50.2371C2.62805 48.9696 4.2118 48.0871 6.0843 47.7296L7.25805 47.5059"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.3613 20.2793H36.1222C37.5925 20.2793 38.7842 21.4712 38.7842 22.9413C38.7842 25.7292 37.0793 28.1188 34.6553 29.1245"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.8017 29.166C8.32344 28.1858 6.57031 25.7683 6.57031 22.9413C6.57031 21.471 7.76219 20.2793 9.23231 20.2793H10.9932"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.5977 12.5137L32.9014 19.2374C33.0089 19.5487 33.2077 19.8087 33.4627 19.9912C33.7189 20.1737 34.0289 20.2787 34.3577 20.2787V22.0462"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.3616 26.4209V30.1496C34.3616 32.2784 33.4429 34.3034 31.8404 35.7059L27.2566 39.7171C26.3591 40.5021 25.2079 40.9346 24.0154 40.9346H21.3404C20.1479 40.9346 18.9966 40.5021 18.0991 39.7171L13.5154 35.7059C13.0879 35.3309 12.7091 34.9134 12.3829 34.4596C11.4879 33.2146 10.9941 31.7096 10.9941 30.1496V20.2784C11.6516 20.2784 12.2366 19.8596 12.4504 19.2371L14.7991 12.3809"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.7298 14.6025L22.6766 17.3917L17.6235 14.6025C14.8156 13.0526 13.0723 10.099 13.0723 6.8917C13.0723 3.60445 15.7371 0.939453 19.0245 0.939453H26.3289C29.6161 0.939453 32.2811 3.60433 32.2811 6.8917C32.2809 10.0988 30.5375 13.0525 27.7298 14.6025Z"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.5352 20.9471C16.7334 20.2051 18.6763 20.2051 19.8745 20.9471"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.4727 20.9471C26.6709 20.2051 28.6138 20.2051 29.812 20.9471"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.7031 23.5508V25.6035"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.6406 23.5508V25.6035"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.965 42.5879L30.8077 43.9283C31.6734 44.558 31.7089 45.8368 30.8793 46.5135L26.3173 50.235L22.6758 45.4913"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.6772 45.4913L19.0357 50.235L14.4737 46.5135C13.6441 45.8368 13.6794 44.5581 14.5453 43.9283L16.3879 42.5879"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.3848 42.5879C19.9163 45.9183 25.4318 45.9183 28.9633 42.5879"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.70703 26.7949V29.9992C7.70703 32.4627 9.70403 34.4597 12.1675 34.4597H17.7702"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.1296 36.0947H19.7046C18.8019 36.0947 18.0703 35.363 18.0703 34.4604C18.0703 33.5578 18.8019 32.8262 19.7046 32.8262H21.1296C22.0322 32.8262 22.7638 33.5578 22.7638 34.4604C22.7638 35.363 22.0322 36.0947 21.1296 36.0947Z"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M63.2249 51.6494C62.1799 58.1219 56.5662 63.0644 49.7987 63.0644C42.2862 63.0644 36.1962 56.9744 36.1962 49.4619C36.1962 46.9981 36.8524 44.6881 37.9974 42.6956L36.2737 37.8806C35.9049 36.8494 36.9012 35.8531 37.9324 36.2219L42.6287 37.9031C44.7099 36.6081 47.1662 35.8594 49.7987 35.8594C56.5662 35.8594 62.1799 40.8019 63.2249 47.2744"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M58.45 51.1016L55.9532 53.4469C55.4408 53.9282 54.7717 54.1931 54.069 54.1931H41.9961"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M58.3945 47.9279L43.5625 44.2572C44.925 41.519 48.006 40.0937 50.9749 40.8286L53.4367 41.4379C56.4057 42.1725 58.4665 44.8702 58.3945 47.9279Z"
          stroke="#FA2846"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M52.3047 40.7186L52.6536 39.3086"
          stroke="#FA2846"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M50.8395 46.6934L48.209 57.3227"
          stroke="#FA2846"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M44.1484 54.5996V57.3301"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M54.1895 54.5996V57.3301"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M52.2066 41.1309L51.416 41.5406C49.9936 42.2779 48.9164 43.583 48.4375 45.1494"
          stroke="#FA2846"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M52.2031 41.1309L52.7114 41.8621C53.6258 43.1777 53.9699 44.8345 53.6629 46.4435"
          stroke="#FA2846"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40.043 58.5773C46.4035 57.1956 52.9866 57.1956 59.3471 58.5773"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.6758 45.4902V54.1939"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.9668 48.7383C5.91755 50.7669 7.01305 53.4763 7.01305 56.3017V63.0639"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M38.3398 56.9043V63.0659"
          stroke="#1D2939"
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3108_24633">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
