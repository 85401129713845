import { cn } from '@v2/utils/cn';
import Link, { LinkProps } from 'next/link';
import React, { HTMLAttributes, PropsWithChildren } from 'react';

export type TextLinkProps = PropsWithChildren<
  LinkProps & HTMLAttributes<HTMLAnchorElement>
> & {
  className?: string;
  target?: string; // Add target attribute to the type definition
};

export function TextLink(props: TextLinkProps) {
  // Destructure target from props and use it or provide a default value
  const { target = '_self', ...rest } = props;

  return (
    <Link {...rest} passHref prefetch={false}>
      <a
        target={target} // Set the target attribute
        className={cn(
          'text-semibold text-[14px] text-primary-500 hover:text-primary-600',
          props.className
        )}
      >
        {props.children}
      </a>
    </Link>
  );
}
