import { FC } from 'react';
import { SVG, SVGComponentProps } from '../Template';

export const Google: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props} stroke="none">
      <path
        d="M18.7511 10.1941C18.7511 9.47471 18.6915 8.94971 18.5626 8.40527H10.1797V11.6525H15.1003C15.0011 12.4594 14.4654 13.6747 13.2749 14.4913L13.2582 14.6L15.9087 16.6123L16.0924 16.6303C17.7788 15.1039 18.7511 12.858 18.7511 10.1941Z"
        fill="#4285F4"
      />
      <path
        d="M10.1793 18.75C12.59 18.75 14.6138 17.9722 16.092 16.6305L13.2745 14.4916C12.5206 15.0068 11.5086 15.3666 10.1793 15.3666C7.81822 15.3666 5.81427 13.8402 5.09992 11.7305L4.99522 11.7392L2.23917 13.8295L2.20312 13.9277C3.67136 16.786 6.68723 18.75 10.1793 18.75Z"
        fill="#34A853"
      />
      <path
        d="M5.09916 11.7307C4.91068 11.1863 4.80159 10.6029 4.80159 10.0002C4.80159 9.39734 4.91068 8.81403 5.08925 8.2696L5.08425 8.15365L2.29366 6.02979L2.20236 6.07235C1.59723 7.25847 1.25 8.59044 1.25 10.0002C1.25 11.4099 1.59723 12.7418 2.20236 13.9279L5.09916 11.7307Z"
        fill="#FBBC05"
      />
      <path
        d="M10.1794 4.63331C11.8559 4.63331 12.9868 5.34303 13.6317 5.93612L16.1516 3.525C14.604 2.11528 12.59 1.25 10.1794 1.25C6.68725 1.25 3.67137 3.21387 2.20312 6.07218L5.09002 8.26943C5.8143 6.15972 7.81825 4.63331 10.1794 4.63331Z"
        fill="#EB4335"
      />
    </SVG>
  );
};
export const Facebook: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props} stroke="none">
      <path
        d="M8.03771 4.88734V7.17734H6.35938V9.97734H8.03771V18.299H11.4827V9.97817H13.7952C13.7952 9.97817 14.0119 8.63567 14.1169 7.16734H11.4969V5.25234C11.4969 4.9665 11.8719 4.5815 12.2435 4.5815H14.1219V1.6665H11.5685C7.95187 1.6665 8.03771 4.469 8.03771 4.88734Z"
        fill="#0D87EF"
      />
    </SVG>
  );
};
