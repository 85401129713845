import { FC } from 'react';
import { SVG, SVGComponentProps } from '../Template';

export const NoTicket: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    // <svg
    //   width="80"
    //   height="80"
    //   viewBox="0 0 80 80"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    <SVG {...props} stroke="currentColor" fill="none" viewBox="0 0 80 80">
      <path
        d="M36.873 60.3115H65.9355"
        stroke="currentColor"
        stroke-width="3.125"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.873 66.5615H65.9355"
        stroke="currentColor"
        stroke-width="3.125"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.2461 46.249V43.124"
        stroke="currentColor"
        stroke-width="3.125"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.2461 58.749V52.499"
        stroke="currentColor"
        stroke-width="3.125"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.2461 68.124V64.999"
        stroke="currentColor"
        stroke-width="3.125"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.9336 54.0615H36.8711V44.6865H65.9336V54.0615Z"
        stroke="currentColor"
        stroke-width="3.125"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <mask
        id="mask0_3589_16743"
        //style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="80"
        height="80"
      >
        <path d="M0 7.62939e-06H80V80H0V7.62939e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3589_16743)">
        <path
          d="M51.0461 10.0426L46.6273 5.62383L39.6852 12.5659L32.743 5.62383L28.3242 10.0426L35.2664 16.9848L28.3242 23.9268L32.743 28.3457L39.6852 21.4035L46.6273 28.3457L51.0461 23.9268L44.1039 16.9848L51.0461 10.0426Z"
          stroke="currentColor"
          stroke-width="3.125"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M54.5312 74.375C54.5312 75.2378 53.8317 75.9375 52.9687 75.9375C52.1058 75.9375 51.4062 75.2378 51.4062 74.375C51.4062 73.5122 52.1058 72.8125 52.9687 72.8125C53.8317 72.8125 54.5312 73.5122 54.5312 74.375Z"
          fill="currentColor"
        />
        <path
          d="M60 74.375H78.4375V36.875H27.5C27.5 40.3266 24.7016 43.125 21.25 43.125C17.7984 43.125 15 40.3266 15 36.875H1.5625V74.375H15C15 70.9234 17.7984 68.125 21.25 68.125C24.7016 68.125 27.5 70.9234 27.5 74.375H45.9375"
          stroke="currentColor"
          stroke-width="3.125"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </SVG>
    //</svg>
  );
};
