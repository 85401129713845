'use -client';

import { cn } from '@v2/utils/cn';
import Image from 'next/image';
import Link from 'next/link';

type Props = {
  width?: number;
  height?: number;
  size?: number;
  className?: string;
  isClickable?: boolean;
  variant?: 'white' | 'black';
};

export function Logo({
  width,
  height,
  className,
  size,
  isClickable,
  variant = 'white',
}: Props) {
  const clickableStyles = isClickable ? 'cursor-pointer' : '';
  const logoVariant = `/logo-${variant}.svg`;

  const widthStyle = size && width ? width : size ? size : 50;
  const heightStyle = size && height ? height : size ? size : 50;

  if (isClickable) {
    <Link href="/v2">
      <Image
        src={logoVariant}
        width={widthStyle}
        height={heightStyle}
        alt="logo"
        className={cn(clickableStyles, className)}
      />
    </Link>;
  }

  return (
    <Image
      src={logoVariant}
      width={widthStyle}
      height={heightStyle}
      alt="logo"
      className={cn(clickableStyles, className)}
    />
  );
}
